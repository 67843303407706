exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-ponuka-tsx": () => import("./../../../src/pages/ponuka.tsx" /* webpackChunkName: "component---src-pages-ponuka-tsx" */),
  "component---src-pages-react-kurz-tsx": () => import("./../../../src/pages/react-kurz.tsx" /* webpackChunkName: "component---src-pages-react-kurz-tsx" */),
  "component---src-pages-solid-principy-v-php-tsx": () => import("./../../../src/pages/solid-principy-v-php.tsx" /* webpackChunkName: "component---src-pages-solid-principy-v-php-tsx" */),
  "component---src-pages-zaklady-programovania-v-javascripte-tsx": () => import("./../../../src/pages/zaklady-programovania-v-javascripte.tsx" /* webpackChunkName: "component---src-pages-zaklady-programovania-v-javascripte-tsx" */)
}

