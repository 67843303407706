import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
// import theme from '@local/styles/theme';
import theme from './src/styles/theme';
import CssBaseline from '@mui/material/CssBaseline';

export const wrapRootElement = ({ element, props }) => {
    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={theme}>{element}</ThemeProvider>
        </>
    );
};
