import { createTheme } from '@mui/material/styles';
// import Link from '@local/components/router/Link';
import { Link } from 'gatsby-material-ui-components';

const theme = createTheme({
    palette: {
        // primary: {
        //     main: '#ffa500',
        // },
    },
    components: {
        MuiLink: {
            defaultProps: {
                component: Link,
            },
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: Link,
            },
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    marginTop: '1rem',
                },
            },
        },
    },
});

export default theme;
